export const breakpointsNumber = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

export const breakpoints = {
  mobileS: `${breakpointsNumber.mobileS}px`,
  mobileM: `${breakpointsNumber.mobileM}px`,
  mobileL: `${breakpointsNumber.mobileL}px`,
  tablet: `${breakpointsNumber.tablet}px`,
  laptop: `${breakpointsNumber.laptop}px`,
  laptopL: `${breakpointsNumber.laptopL}px`,
  desktop: `${breakpointsNumber.desktop}px`,
};
